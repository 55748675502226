<template>
  <div >
    <el-select
      v-model="selected_coop"
      :placeholder="placeholder"
      value-key="id"
      filterable
      remote
      :remote-method="getcoop"
      :clearable="clearable"
      @change="changecoop"
      ref="agentSelect"
      @hook:mounted="cancalReadOnly"
      @visible-change="cancalReadOnly"
      :focus-onec="getcoop"
    >
      <el-option
        v-for="item in coop_list"
        :key="item.id"
        :label="item.title"
        :value="item.id"
        @click.native="coopChnage(item)"
      >
      </el-option>
    </el-select>
  </div>
</template>
  
  <script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "输入合作商名称/联系人电话",
    },
    info:{
       type: String,
      default: "",
    },
    condition:{
       type: Object,
      default: {},
    },
    clearable:{
       type: Boolean,
      default: false,
    },
  },
  watch:{
    info:{
      immediate:true,
      handler(nv,ov){
        if(nv){
          this.selected_coop = nv
        }
      }
    }
  },
  data() {
    return {
      coop_list: [],
      selected_coop: "",
    };
  },
  methods: {
    changecoop() {
      this.$emit("update:coopid", this.selected_coop);
    },
    coopChnage(item) {
      this.$emit("change", item);
    },
    getcoop(query) {
      this.coop_list = [];
      const numberReg = /^\d/;
      if (query && typeof query == "string") {
        let data = {
          ...this.condition
        };
        numberReg.test(query) ? (data.phone = query) : (data.title = query);
        let url = "/user/cooperator/queryListPage";
        this.$axios
          .get(url, {
            params: data,
          })
          .then((res) => {
            this.coop_list = res.data.data;
            this.$emit("search", res.data.data);
          });
      }
    },
    clear() {
      this.coop_list = [];
      this.selected_coop = "";
    },
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const Selects = this.$refs;

          if (Selects.agentSelect) {
            const input =
              Selects.agentSelect.$el.querySelector(".el-input__inner");
            input.removeAttribute("readonly");
          }
        }
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>